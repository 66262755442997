const MonthsReverseNameService = {
    'Jan': 'jan',
    'Feb': 'feb',
    'Mar': 'mar',
    'Apr': 'apr',
    'May': 'may',
    'Jun': 'jun',
    'Jul': 'jul',
    'Aug': 'aug',
    'Sep': 'sep',
    'Oct': 'oct',
    'Nov': 'nov',
    'Dec': 'dec'
};

export default MonthsReverseNameService;
